// Header.js

import React from 'react';
import logo from './LOGO.png'; // Asegúrate de que esta sea la ruta correcta al logo
import './Header.css'; // Importa los estilos específicos de la cabecera

function Header() {
  return (
    <nav className="navbar navbar-expand-lg navbar-custom">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img src={logo} alt="Logo" className="logo" />
          <label className='ciroLabel'>CiroDeLeon.com</label>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {/* Aquí puedes agregar más elementos de navegación si es necesario 
            <li className="nav-item">
              <a className="nav-link" href="URL_DE_TU_PORTAFOLIO">Portafolio</a>
            </li>
            */}
            <li className="nav-item">
              <a className="nav-link" href="/CodeContextualizer">Code Contextualizer</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
