import React from 'react';
import { BrowserRouter as Router, Route,Routes, Link } from 'react-router-dom';
import CodeContextualizer from './CodeContextualizer';
import MainMenu from './MainMenu';
import Header from './Header';
import Footer from './Footer';
import CodeContextualizerLandingPage from './CodeContextualizerLandingPage';


function App() {
  return (
    <Router>
      <Header></Header>
      <div className="container">
        

        <Routes> {/* Aquí se cambió <Switch> por <Routes> */}
          <Route path="/CodeContextualizer" element={<CodeContextualizer />} />
          <Route path="/" element={<CodeContextualizerLandingPage />} />
          <Route path="/DownloadCodeContextualizer" element={<CodeContextualizerLandingPage />} />       
        </Routes>         
      </div>
      <Footer></Footer>
    </Router>
  );
}

export default App;