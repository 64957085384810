// Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importar los estilos de Bootstrap
import logo from './magicCode3.png'; // Asegúrate de que esta sea la ruta correcta al logo
import './CodeContextualizer.css'; // Importa tu archivo CSS personalizado
import { Link } from 'react-router-dom';

function CodeContextualizer() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  return (
    <div className="container">
    <div>
    <a className="navbar-brand" href="#">
       <img src={logo} alt="Logo" height="100" className="d-inline-block align-middle mr-2" />
    </a>
    </div>
    <div className="page-background">      
      <div className="container row">
        
          <div className="col-md-2">
            {/* Columna vacía a la izquierda */}
          </div>
          <div className="col-md-8">
            {/* El contenido principal va aquí */}
            
            <h2>Code Contextualizer</h2>
            <p>It´s a tool that create a bridge between your <b>code</b> and Large Language Models like <b>chat-gpt</b>,gemini,claude etcetera</p>
            <div className="video-responsive">
              {/* El iframe sin width ni height en línea */}
              <iframe
                src="https://www.youtube.com/embed/919_mSFbmHI"
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen></iframe>
            </div>
            <br />
            <p>this tool can <b>improve</b> your <b>productivity</b> in an exponential way.</p>
            <Link to="/DownloadCodeContextualizer" style={{ textDecoration: 'underline', color: 'blue' }}>Download</Link>
          </div>
          <div className="col-md-2">
            {/* Columna vacía a la derecha */}
          </div>
        
      </div>
    </div>
    </div>
  );
}

export default CodeContextualizer;