import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './magicCode3.png';
import './CodeContextualizerLandingPage.css';
import Cookies from 'js-cookie';
import googleLogo from './googleLogo.svg';





function CodeContextualizerLandingPage() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const token = Cookies.get('JWT');
      console.log(token);
      if (token) {
        console.log(token);
        responseGoogleOnSuccess();
        clearInterval(intervalId); // Limpia el intervalo una vez que se encuentra el token
      }
    }, 1000); // Comprueba cada 1 segundo
  
    // Limpieza en caso de que el componente se desmonte antes de encontrar el token
    return () => clearInterval(intervalId);
  }, []);

  const validateForm = () => {
    if (!firstName || !lastName || !email || !password || !password2) {
      setErrorMessage('Todos los campos son obligatorios.');
    } else if (password !== password2) {
      setErrorMessage('Las contraseñas no coinciden.');
    } else {
      setErrorMessage('');
    }
  };

  const responseGoogleOnSuccess = async (response) => {
    //console.log(response);
    // Leer el token JWT de la cookie
    const token = Cookies.get('JWT'); // Reemplaza 'jwt' con el nombre correcto de tu cookie
    const id=Cookies.get('id'); 
    if(token) {
      //console.log(token);
      //alert('fue autenticado por google');
      const urlBaseX='https://cirodeleon.com:9090/api';
      //const urlBaseX = 'http://localhost:9090/api';
      const url = `${urlBaseX}/actExterno/createActExternoFromActUsuario/actUsuario/${id}`; // Asegúrate de reemplazar {idActUsuario} con el valor correspondiente si es necesario
  
      try {
        const responsex = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Incluye el token en el encabezado
            // Añade aquí cualquier otro encabezado que necesites
          },
          body: '{}'
          // Si tu endpoint espera un cuerpo, añádelo aquí
        });
        console.log(responsex);
        const jsonResponse = await responsex.json();
        //('jajaja '+jsonResponse.message);
        if (responsex.status==201) {
          //alert('entro en ok');
          //const jsonResponse = await responsex.json();
          console.log(jsonResponse);
          var idActExterno=jsonResponse.data.id;
          const url2 = urlBaseX+'/signup/createCodeContextualizerDemoLicense/actExterno/'+idActExterno;

        
          const response2 = await fetch(url2, {
            method: 'POST'          
          });

          if(response2.ok){
             const urlDescarga=urlBaseX+'/signup/downloadCodeContextualizer';
             window.location.href = urlDescarga;
          }else{             
              console.log(response2);             
          }
          
          // Procesa aquí la respuesta como necesites
        } else {
          // Manejar errores, por ejemplo, mostrando un mensaje al usuario
          
          alert('ya usted tiene una licencia activa por lo cual el servidor envia este mensaje: '+jsonResponse.message);
          const urlDescarga=urlBaseX+'/signup/downloadCodeContextualizer';
          window.location.href = urlDescarga;
          console.error('Error en la petición:', responsex.statusText);
        }
      } catch (error) {
        console.error('Error al realizar la petición:', error);
      }
    } else {
      console.error('No se encontró el token JWT en las cookies');
    }
  };

  const responseGoogleOnFailure = (response) => {
    console.log(response);
    // Aquí manejarías la respuesta, por ejemplo, enviando información al backend
  };

  const handleSubmit = async (e) => { // Asegúrate de que esta línea tiene la palabra clave 'async'
    e.preventDefault();
  
    // Crear el objeto de datos que se enviará al servidor
    const userData = {
      nombres: firstName,
      apellidos: lastName,
      correo: email,
      password: password,
    };
    

    
    
    const urlBase='https://cirodeleon.com:9090/api/signup';
    //const urlBase='http://localhost:9090/api/signup';

    // Definir la URL del servicio REST
    const url = urlBase+'/actExterno';
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
  
      // Verificar si la solicitud fue exitosa
      if (response.ok) {
        const jsonResponse = await response.json();
        // Manejar la respuesta aquí, por ejemplo:
        var idActExterno=jsonResponse.data.id;

        const url2 = urlBase+'/createCodeContextualizerDemoLicense/actExterno/'+idActExterno;

        
        const response2 = await fetch(url2, {
          method: 'POST'          
        });

        if(response2.ok){
          const urlDescarga=urlBase+'/downloadCodeContextualizer';
          window.location.href = urlDescarga;
        }
        // Navegar a otra ruta o mostrar un mensaje de éxito
      } else {
        // Manejar los errores de la solicitud
        //console.log(response);
        const jsonResponse = await response.json();
        alert(jsonResponse.message+' '+response.status);
        if(response.status==400 && jsonResponse.message.includes('registrado')){
           const urlDescarga=urlBase+'/downloadCodeContextualizer';
           window.location.href = urlDescarga;
        }

        throw new Error('Something went wrong on api server!'+jsonResponse.message);
      }
    } catch (error) {
      // Manejar errores de red o de conexión
      console.error(error);
    }
  };

  return (
    <div className="container">
      <div>
        <a className="navbar-brand" href="#">
          <img src={logo} alt="Logo" height="100" className="d-inline-block align-middle mr-2" />
        </a>
      </div>
      <div className="page-background">
        <div className="container row">
          
            
            <div className="col-md-12">
              {/* El contenido principal va aquí */}
              <form onSubmit={handleSubmit} className="form-container">
                <h2>Download Form</h2>
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input type="text" className="form-control" id="firstName" value={firstName} onChange={(e) => {setFirstName(e.target.value); validateForm();}} />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input type="text" className="form-control" id="lastName" value={lastName} onChange={(e) => {setLastName(e.target.value);validateForm();}} />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control" id="email" value={email} onChange={(e) => {setEmail(e.target.value);validateForm();}} />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input type="password" className="form-control" id="password" value={password} onChange={(e) => {setPassword(e.target.value);validateForm();}} />
                </div>
                <div className="form-group">
                  <label htmlFor="password2">Confirm Password</label>
                  <input type="password" className="form-control" id="password2" value={password2} onChange={(e) => {setPassword2(e.target.value);validateForm();}} />
                </div>

                <div className="form-group">
                   {(firstName && lastName && email && password && password2 && password !== password2) && <div className="alert alert-danger" role="alert">Las contraseñas no coinciden.</div>}
                   <button type="submit" className="btn btn-primary" disabled={!firstName || !lastName || !email || !password || !password2 || password !== password2}>Download</button>
                </div>

                <div className="form-group">
                    <a href="https://cirodeleon.com:9090/oauth2/authorization/google" className="btn google-auth-button">
                       <img src={googleLogo} alt="Google logo" className="google-logo" />
                       <span>Download with Google</span>
                    </a>
                </div>




              </form>
            </div>
            
          
        </div>
      </div>
    </div>
  );
}

export default CodeContextualizerLandingPage;

