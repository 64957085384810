// File: MainMenu.js

import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function MainMenu() {
    const navigate = useNavigate();
    const [esAdministrador, setEsAdministrador] = useState(false);
    const [esExterno, setEsExterno] = useState(false);
    const token = localStorage.getItem('token');

    

    return (
        <div className="container mt-5">
            <h2>Menú Principal</h2>
            <br></br>
            <nav>
                <ul>
                <li><Link to="/listarProductos">Vista Productos</Link></li>
                <li><Link to="/listarProductos">Vista Productos</Link></li>
                    
                </ul>
            </nav>
           
        </div>
    );
}

export default MainMenu;
