// Footer.js

import React from 'react';
import './Footer.css'; // Asegúrate de crear y referenciar el archivo CSS adecuado

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <span className="text-muted">© 2024 CiroDeLeon.com All rights reserved.</span>
        <div className="social-icons">
          {/* Asegúrate de reemplazar # con tus propias URLs */}
          <a href="https://www.linkedin.com/in/ciro-cayo-de-le%C3%B3n-burgos-41734438/" className="social-icon">LinkedIn</a>
          <a href="https://github.com/CiroDeLeon" className="social-icon">GitHub</a>
          <a href="https://twitter.com/ciro_de_leon" className="social-icon">Twitter</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
